var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ad-guard-dns-info-wrapper" }, [
    _vm.enabled !== null
      ? _c("div", { staticClass: "enable-status" }, [
          _vm.enabled
            ? _c("p", { staticClass: "status connected" }, [
                _c("span", [_vm._v("✔")]),
                _vm._v(" Enabled"),
              ])
            : _c("p", { staticClass: "status not-connected" }, [
                _c("span", [_vm._v("✘")]),
                _vm._v(" Disabled"),
              ]),
        ])
      : _vm._e(),
    _vm.dnsInfo.length > 0
      ? _c(
          "p",
          {
            staticClass: "expend-details-btn",
            on: { click: _vm.toggleShowData },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.showData
                    ? _vm.$t("widgets.general.show-less")
                    : _vm.$t("widgets.general.show-more")
                ) +
                " "
            ),
          ]
        )
      : _vm._e(),
    _vm.showData && _vm.dnsInfo.length > 0
      ? _c(
          "div",
          { staticClass: "dns-info" },
          _vm._l(_vm.dnsInfo, function (item, index) {
            return _c("div", { key: index, staticClass: "row" }, [
              _c("span", { staticClass: "lbl" }, [
                _vm._v(_vm._s(item.lbl) + ": "),
              ]),
              _c("span", { staticClass: "val" }, [
                _vm._v(_vm._s(_vm._f("renderVal")(item.val))),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }